import * as Styles from './AccountAgreements.styles';

const accountAgreementLinks = [
  {
    label: 'Customer Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Customer_Agreement_v5.6_20200424.pdf',
  },
  {
    label: 'Electronic Consent Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Electronic_Consent_Agreement_v1.3_20140713.pdf',
  },
  {
    label: 'Online Services Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Online_Services_Agreement_v1.4_20140713.pdf',
  },
  {
    label: 'AOS Form CRS',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/tb/AOS_MyIPO_Form_CRS.pdf',
  },
  {
    label: 'Privacy Policy',
    url: '/static/docs/Privacy_Policy_Revision_2025.pdf',
  },
  {
    label: 'Business Continuity Plan',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/tb/MyIPO_Business_Continuity_Plan_Disclosure.pdf',
  },
  {
    label: 'Anti-money Laundering Policy',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Anti%20Money%20Laundering%20Policy_v1_20090130.pdf',
  },
  {
    label: 'Regulation Best Interest (Reg BI) - An Overview',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/RegBI_Overview.pdf',
  },
];

export const AccountAgreementsList = () => {
  return (
    <>
      {accountAgreementLinks.map(agreement => (
        <div key={`${agreement.label}_key`} className={Styles.agreementLink}>
          <i className='ri-download-2-fill' />
          <a className={Styles.link} href={agreement.url} target='_blank' rel='noreferrer'>
            {agreement.label}
          </a>
        </div>
      ))}
    </>
  );
};
